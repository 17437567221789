import { gql} from "@apollo/client";

// --------------------------------------------------- 

export const generateHistoryGQL = gql`
mutation generateHistory( $id: ID! $input:CardUpdate!){
  generateHistory( id:$id, input: $input) {
    success
    error
    id
    data
  }
}
`;
/*
export const getFlowGQL = gql`
query{
  getFlow(
    id : "62bc2a2bbec7bf7236122c6e"
  ){
    id
    flow{
      cards{
        id
        title
        category
        data
      }
    }
    success
    error
  }
}
`;
*/
export const getFlowGQL = gql`
query getFlow($id: ID!){ getFlow(id:$id){
    id
    flow{
    cards{
        id
        title
        subtitle
        category
        data
        linkto
        img
    }
    }
    success
    error
}
}
`;



export const getCardGQL = gql`
query getCard($id: ID!){
    getCard( id:$id){
    id
    card{
        id
        title
        subtitle
        category
        data
        linkto
        img
      }
    success
    error
    }
}
`;


//write Query for Reactvar
//this query is independent of server graphql

//react var name and field name must be same

export const GET_DARK_MODE = gql`
  query getDarkMode {
      darkMode @client
  }
     
`    
//---------------------------------------------
export const userGQL = gql`
  query UserQuery {
    userData {
      id
      age
      details
      access_date_utc
    }
  }
`;


export const getUserByName = gql`
query getUserByName( $username: String!){
  getUserByName( username:$username){
    id
    user{
			id
      username
      flowid
    }
    success
    error
  }
}
`;

export const getUserByToken = gql`
query getUserByToken( $token: String!){
  getUserByToken( token:$token){
    id
    user{
			id
      username
      flowid
      cards{
        category
        title
        subtitle
        data
        linkto
        color
        img
        id
        editable
        source
        shape
      }
      flows{
        id
        category
        title
        editable
        source
      }
    }
    success
    error
  }
}
`;

export const logIn = gql`
mutation logIn( $username:String! $password: String!){
  logIn( username: $username, password: $password) {
    success
    token
    error
    id
  }
}
`;

export const signUpLoginM = gql`
mutation signUpLoginM( $username:String! $password: String!){
  signUpLoginM( username: $username, password: $password) {
    success
    token
    error
    id
  }
}
`;

export const generateUserM = gql`
mutation generateUserM( $username:String! $password: String! $userdata: String!){
  generateUserM( username: $username, password: $password, userdata: $userdata) {
    success
    token
    error
    id
  }
}
`;

export const createCardMGQL = gql`
mutation createCardM( $input:CardInput!){
  createCardM( input: $input) {
    success
    error
    id
    data
  }
}
`;


export const createTaskGQL = gql`
mutation createTask( $input:TaskInput!){
  createTask( input: $input) {
    success
    error
    id
    data
  }
}
`;


export const getTasksByExecuterGQL = gql`
query Query($getTasksByExecuterId: ID!) {
  getTasksByExecuter(id: $getTasksByExecuterId) {
    success
    id
    error
    tasks {
      category
      title
      data 
      status
    }
  }
}
`;

export const allTasks = gql`
query allTasks{
    id
    owner
    title
    category
    data
    executers {
      username
    }
    executeAt
    targets {
      category
    }
    status
    editable
    source
    createdAt
    updatedAt
}
`;

export const getYMGroupListGQL = gql`
query getYMGroupList($code: String!) {
  getYMGroupList(code: $code) {
    data
    error
    id
    idx
    success
  }
}
`;

export const getYMCountGQL = gql`
query getYMCount($code: String!){ getYMCount(code:$code){
    code
    count
    success
    error
}
}
`;

export const getYMExecuterGQL = gql`
query getYMExecuter($uid: ID!){ getYMExecuter(uid:$uid){
    id
    executer{
      id
      authorized
      category
      name
      data 
      status
    }
    success
    error
}
}
`;

export const createYMExecuterGQL = gql`
mutation createYMExecuter( $input:YMExecuterInput!){
  createYMExecuter(input: $input) {
    error
    executer {
      name
      id
      authorized
      user
    }
    id
    success
  }
}
`;


export const createYMGroupGQL = gql`
mutation createYMGroup( $input:YMGroupInput!){
  createYMGroup( input: $input) {
    success
    error
    id
    data
  }
}
`;


export const getYMGroupsByExecuterGQL = gql`
query getYMGroupsByExecuter($getYMGroupsByExecuterId: ID!) {
  getYMGroupsByExecuter(id: $getYMGroupsByExecuterId) {
    success
    id
    error
    groups {
      id
      category
      title
      data 
      status
      reservedAt
    }
  }
}
`;


export const createYMTargetGQL = gql`
mutation createYMTarget( $input:YMTargetInput!){
  createYMTarget( input: $input) {
    success
    error
    id
    data
  }
}
`;


export const getYMTargetsByExecuterGQL = gql`
query Query($getYMTargetsByExecuterId: ID!) {
  getYMTargetsByExecuter(id: $getYMTargetsByExecuterId) {
    success
    id
    error
    targets {
      id
      category
      title
      data 
      status
      reservedAt
    }
  }
}
`;

export const getYMTargetsByGrGQL = gql`
query Query($gId: ID!) {
  getYMTargetsByGr(gid: $gId) {
    success
    id
    error
    targets {
      id
      category
      title
      data 
      status
      executers {
        id
        name
      }
      reservedAt
    }
  }
}
`;

export const getYMTargetsByExAndGrGQL = gql`
query Query($eId: ID! $gId: ID!) {
  getYMTargetsByExAndGr(eid: $eId, gid: $gId) {
    success
    id
    error
    targets {
      id
      category
      title
      data 
      status
      executers {
        id
        name
      }
      group {
        id
        reservedAt
      }
      reservedAt
    }
  }
}
`;


export const createYMTaskGQL = gql`
mutation createYMTask( $input:YMTaskInput!){
  createYMTask( input: $input) {
    success
    error
    id
    data
  }
}
`;

export const getYMTaskGQL = gql`
query getYMTask($id: ID!){ getYMTask(id:$id){
    id
    task{
      id
      category
      title
      data 
      status
    }
    success
    error
}
}
`;

export const getYMTasksByGrAndTgGQL = gql`
query Query($gId: ID! $tgId: ID!) {
  getYMTasksByGrAndTg(gid: $gId, tgid: $tgId) {
    success
    id
    error
    tasks {
      id
      category
      title
      data 
      status
    }
  }
}
`;

export const updateYMTaskGQL = gql`
mutation updateYMTask( $id: ID! $input:YMTaskInput!){
  updateYMTask( id: $id, input: $input) {
    success
    error
    id
    data
  }
}
`;

/**
    // direct
    const { loading, error, data, refetch, networkStatus } = useQuery(
        getUserByName,
        {
        variables: {username: email},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
        // pollInterval: 500
        }
    );
    if (networkStatus === 4) return <p>Refetching!</p>;
    if (loading) return <p>loading</p>;
    if (error) return <p>Error!: {error}</p>;
    or     
    useEffect( ()=>{},[data]);

    // indirect    
    const [ getUserByNameHandler, getUserByNameRequest] = useLazyQuery(getUserByName, {
        onCompleted: (data) => {
            if(data.getUserByName.success === true ) {
                if (data.getUserByName.length > 0) {
                }
            }
        },
    });
    getUserByNameHandler({
        variables: {username: email},
        });
    
    // mutation    
    const [ signUpLoginHandler, signUpLoginRequest] = useMutation(signUpLoginM , {
        //here i am doing something after the mutaion finished
        onCompleted(data) {
        if(data.signUpLoginM.success === true ) {
        setMessage('');
            //setLogin( data.signUpLoginM.id === ''? 2:1);

            userDatas({...userDatas(), uid:data.signUpLoginM.id, uname: email});

            //localStorage.setItem('exName' , email);
            //localStorage.setItem('exID' , data.signUpLoginM.id);
            //localStorage.setItem('userToken' , data.signUpLoginM.token);
        }
        else{
            setMessage(data.signUpLoginM.error);
        }
        }
    });

    signUpLoginHandler({variables: {username:email , password:password}})
    .then((result) => {
        const {data : {signUpLoginM  : {success , token , id}}} = result
        //console.log('user token ',token)
        localStorage.setItem('userToken',token);
        localStorage.setItem('userID',id); 
    })
    .catch(err => {
        console.log('login error',signUpLoginRequest.error)
        console.log(err)
    })



https://liflo.io/graphql

query{
  allUsers{
    id
     username
  }
} 

query{
  getUser(
    id : "5baa268b6279ed51678e8ebd"
  ){
    success
    user{
      username
      id
      flows{
        id
        title
      }
      flowid
      
    }
    error
  }
}

mutation{
  signUp(
    username: "test27"
    password: "pass1"
  ){
    id
    success
    token
    error
  }
}


mutation{
  logIn(
    username: "blueton12@gmail.com"
    password: "eugene12"
  ){
    id
    success
    token
    error
  }
}

mutation{
  signUp(
    username: "blueton12@gmail.com"
    password: "eugene12"
  ){
    id
    success
    token
    error
  }
}

query{
  getUserByToken(
    token : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImJsdWV0b24xMkBnbWFpbC5jb20iLCJfaWQiOiI2MzMwMmQ4ZWY2OWQ0YjAzMWQ2NDk1MmEiLCJpZCI6IjYzMzAyZDhlZjY5ZDRiMDMxZDY0OTUyYSIsImlhdCI6MTY2NDEwMTc3NH0.OFZ7Ziu7UIoPzhdcC3tZ5XUswfJiGTklhLYyyFxCkow"
  ){
    success
    id
    user{
      username
      id
    }
  }
}

https://github.com/Ashish1101/GrapgQlLearning/blob/de67e55326a48aafd94b0d4f6e3e9bff5d4fba20/client/src/Components/Login.jsx

 */
/*
import { gql, useMutation, useQuery } from "@apollo/client";

// --------------------------------------------------- 
export const getUserByToken = gql`
query getUserByToken( $token: String!){
  getUserByToken( token:$token){
    id
    user{
			id
      username
      flowid
      cards{
        category
        title
        subtitle
        data
        linkto
        color
        img
        id
        editable
        source
        shape
      }
      flows{
        id
        category
        title
        editable
        source
      }
    }
    success
    error
  }
}
`;

export const signUp = gql`
mutation signUp( $username:String! $password: String!){
  signUp( username: $username, password: $password) {
    success
    token
    error
    id
  }
}
`;

export const logIn = gql`
mutation logIn( $username:String! $password: String!){
  logIn( username: $username, password: $password) {
    success
    token
    error
    id
  }
}
`;

const Login = (props:any) => {

    const [doLogin, { loading, error, data }] = useMutation(logIn, {
      // pollInterval: 500
      refetchQueries: [getUserByToken, "getUserByToken"]
    });
  
    if (loading) return <p>login ...</p>;
    if (error) return <p>Error!: {error.message}</p>;
    console.log(data);

    function login(){
      
      doLogin({ variables: {   
        "username": props.id, 
        "password": props.password},
        notifyOnNetworkStatusChange: true,  
      });
    }    
}
*/