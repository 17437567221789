import { useReactiveVar } from "@apollo/client";
import { MoodBadOutlined, SentimentDissatisfiedOutlined, SentimentNeutralOutlined, SentimentSatisfiedAlt } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { useRef,Fragment } from "react";
import styled from "styled-components";
import { resultDatas, taskDatas, userDatas } from "../../hoc/cache";

const Title = styled.h1`
  text-align: center;
  font-family: "NanumC", "Roboto", sans-serif;
  font-weight: bold;
  color: #000;
  margin-bottom: 0px;
  margin-top: 10px;
`;


const SelectList = ({ cardid, cases,selectCase,switchAlign,switchDir, vert}:{cardid:string, cases:[], selectCase:any, switchAlign:string, switchDir:number, vert:boolean}) => {

    //const { datas:[], selectCase, switchAlign, switchDir} = props;
    const { width : cWidth, height: cHeight } = useReactiveVar(userDatas);
    const { id : id, data: data, } = useReactiveVar(resultDatas);
    const prevCardID = useRef('');

    let fH = cHeight / 25 | 0;
    if( fH > 42 ) fH = 42;

    function getIcon(idx:number){ 
        // SentimentDissatisfiedOutlined / SentimentNeutralOutlined
        if( idx === 0 )
            return <SentimentSatisfiedAlt viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;
        else if( idx === 1 )
            return <SentimentNeutralOutlined viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;
        else if( idx === 2 ) 
            return <MoodBadOutlined viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;
        else if( idx === 3 ) 
            return <SentimentDissatisfiedOutlined viewBox="0 0 10 10" sx={{overflow:'visible', display: 'inherit', marginRight: '30px', marginTop: '-24px'}}/>;  
        else
            return <Fragment></Fragment>;      
    }

    let bspace = {xs: 1, sm: 1, md: 1};
    if( vert )
        bspace = {xs: 5, sm: 3, md: 2};

    let select = "";    
    if( taskDatas().history[cardid] !== undefined ){ // && taskDatas().history[cardid].outputs.length > 1 )
        let o = taskDatas().history[cardid].outputs;
        if( o.length > 1)
            select = taskDatas().history[cardid].select;
    } 
    prevCardID.current = '';    
    return  (
    <Stack direction={switchDir===0?'column':'row'} justifyContent={switchAlign}  spacing={bspace} >
    {
        cases.map((item:any,idx) => {
            let title = item.title;
            let nIcon = 9;
            let nRule = 0;
            
            let cmds = [...title.matchAll(/\[([^\]]+)\]+/g)];

            if( cmds.length !== 0 ){
                // [;#] - menu icon
                // [r;##] - completed by check localStorage.getItem(`${101}`) === '1'
                // [keyword]
                //let nPos = item.title.search(/\[;[0-9]\]+/g);    

                let nPos = cmds[0].index;
                let ccmds = [...cmds[0][0].matchAll(/(\s*|\d+);/g)];
                if( ccmds.length === 0 ){ // keyword
                    if( cmds[0][0] === '[User]' ){
                        if( userDatas().uid !== '') title = '내 카드';
                        else title = '사용자 등록';
                    }
                }
                else{

                    if( ccmds[0].index === 1 ){
                        nIcon = parseInt(cmds[0][0][2],10);
                        title =  item.title.substring(4);
                    }
                    else {
                        let nRPos = item.title.search(/\[r;[1-9][0-9]\]+/g);    
                        if( nRPos !== -1 ){
                            nRule = parseInt(item.title.substring(nRPos+3,nRPos+5),10);
                            if( nRule === 10 && localStorage.getItem(`${1}`) === '1') nRule = -1;
                            if( nRule === 11 && localStorage.getItem(`${2}`) === '1') nRule = -1;
                            if( nRule === 12 && localStorage.getItem(`${3}`) === '1') nRule = -1;
                            if( nRule === 13 && localStorage.getItem(`${4}`) === '1') nRule = -1;
                            if( nRule === 14 && localStorage.getItem(`${5}`) === '1') nRule = -1;
                            if( nRule === 15 && localStorage.getItem(`${51}`) === '1') nRule = -1;
                            if( nRule === 16 && localStorage.getItem(`${52}`) === '1') nRule = -1;
                            if( nRule === 17 && localStorage.getItem(`${53}`) === '1') nRule = -1;
                            if( nRule === 18 && localStorage.getItem(`${54}`) === '1') nRule = -1;
                            if( nRule === 19 && localStorage.getItem(`${55}`) === '1') nRule = -1;            
                            if( nRule === 20 && localStorage.getItem(`${101}`) === '1') nRule = -1;            
                            title =  item.title.substring(6) + (nRule === -1?' - 완료 했습니다.':'');
                        }    
                    }
                }
            }
            
            let cardID = item.card;
            if( cardID === 'none') cardID = prevCardID.current;
            else prevCardID.current = cardID;
            //if( item.id === select )

            //console.log('SelectList->id:'+id+',nIcon:'+nIcon);
            // (nIcon===9 && id === '')?'contained':
            return  (
                <Button key={item.title} 
                    variant={(nIcon===9 && id === '')?'contained':'outlined'}
                    startIcon={getIcon(nIcon)} 
                    color={(nIcon===9)?'primary':'primary'} 
                    disabled={(nRule ===-1)?true:false}
                    sx={{
                        justifyContent:((nIcon===9)?'center':'flex-start'), 
    //                    backgroundColor:(nIcon===9 && id === '')?'':( item.id === select )?'#f00':'#ffff', 
                        backgroundColor:(nIcon===9 && id === '')?'':'#ffff', 
                        height:'45px',
                        "&:hover": {
                            background: (nIcon===9)?(id === '')?"#aaaa":"#fff":"#fff", // <- add here your desired color, for demonstration purposes I chose red
                            borderColor: '#83c3f7',
                        },
                        '&:active': {
                            boxShadow: 'none',
                            borderColor: '#00003f00',
                        },
                        '&:focus': {
                            boxShadow: 'none',
                            borderColor: '#83c3f7',
                        },
                    }}
                    onClick={() => nRule === -1?{}:selectCase(item.id, cardID, item.outputs, item.pt, item.wt)}>
                        {nIcon !== 9 && <Title style={{fontSize: vert?'35px':fH+'px', marginTop:'0px'}}>{title}</Title>}
                        {nIcon === 9 && title}
                </Button>
            )
        })           
    }
    </Stack>);
};

/*
0 0 0 0.2rem rgba(0,123,255,.5)'
{datas.map((item:any) => {
    return  <Button key={item.title} variant="outlined" 
    startIcon={getIcon()} color="success"  
    onClick={() => selectCase(item.id, item.card, item.outputs, item.pt, item.wt)}>
    {item.title}
    </Button>;
    })}
    */

export default SelectList;